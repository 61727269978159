/* eslint-disable lingui/no-unlocalized-strings */
import _ from 'lodash';
import { IUpdateAccountLevel, ProfileData } from 'src/utils/types/auth';
import { BetSlipItemInterface } from 'src/utils/types/sportsbook';
import { Observable } from './etc';

type Rudder = typeof import('rudder-sdk-js');

const rudder = new Observable<Rudder>();
let loading = false;

const getRudderstack = async (): Promise<Rudder | undefined> => {
  let current = rudder.current();
  if (current) return Promise.resolve(current);

  if (loading) {
    return new Promise<Rudder>((resolve) => {
      rudder.subscribe((n) => {
        resolve(n);
      });
    });
  }

  loading = true;
  const sdk = await import('rudder-sdk-js');
  current = await new Promise<Rudder>((resolve) => {
    sdk.load(
      process.env.NEXT_PUBLIC_WRITE_KEY ?? '',
      process.env.NEXT_PUBLIC_DATA_PLANE_URL ?? '',
      { integrations: { All: true } },
    );
    sdk.ready(() => {
      resolve(sdk);
    });
  });
  loading = false;
  rudder.next(current);

  return current;
};

function withRudderstack<
  InputFn extends (...args: any[]) => any,
  Args extends Parameters<InputFn>,
>(fn: (r: Rudder, ...arg: Args) => any) {
  return async (...args: Args) => {
    const r = await getRudderstack();
    if (!r) return;

    return fn(r, ...args);
  };
}

export const trackPageView = withRudderstack((r) => {
  const [title] = document.title.split(' | ');
  r.page(title);
});

export const trackLogout = withRudderstack((r, userId: number | string) => {
  r.track('Logout', { userId: String(userId) });
  r.reset(true);
});

export const trackBetButtonClicked = withRudderstack(
  async (r, userId?: number, bs?: BetSlipItemInterface) => {
    r.track('Bet Button Clicked', {
      userId: String(userId),
      sportId: bs?._rudderstack.sportId,
      tournamentId: bs?._rudderstack.tournamentId,
      eventId: bs?.eventId,
      marketId: bs?.marketId,
      outcomeId: bs?.outcomeId,
    });
  },
);

interface WalletPayload {
  userId?: number | string;
  currency?: string;
  network?: string;
}

export const trackWithdrawalInitiated = withRudderstack(
  async (r, payload: WalletPayload) => {
    r.track('Withdrawal Initiated', {
      ...payload,
      userId: String(payload.userId),
    });
  },
);

interface DepositPayload {
  userId?: number | undefined;
  anonymousId?: string;
  source: string;
}

export const trackDepositInitiated = withRudderstack(
  async (r, payload: DepositPayload) => {
    r.track('User Deposit Initiated', {
      ...payload,
      ...(payload.userId
        ? { userId: String(payload.userId) }
        : { anonymousId: r.getAnonymousId() }),
    });
  },
);

export const trackDepositViewAddress = withRudderstack(
  async (r, payload: WalletPayload) => {
    r.track('View Deposit Address', {
      ...payload,
      userId: String(payload.userId),
    });
  },
);

export const trackDepositCopyAddress = withRudderstack(
  async (r, payload: WalletPayload) => {
    r.track('Copy Deposit Address', {
      ...payload,
      userId: String(payload.userId),
    });
  },
);

export const trackRegisterInit = withRudderstack(async (r, source: string) => {
  r.track(`User Registration Initiated`, {
    anonymousId: r.getAnonymousId(),
    source,
  });
});

export const trackRegisterInfo = withRudderstack(
  async (r, source: { dateOfBirth: string }) => {
    r.track('User Registration Basic Info Submitted', {
      anonymousId: r.getAnonymousId(),
      source,
    });
  },
);

// const trackRegisterOTP = withRudderstack(async (r) => {
//   r.track('User Registration OTP Submitted', {
//     anonymousId: r.getAnonymousId(),
//   });
// });

export const trackLogin = withRudderstack(async (r) => {
  r.track('User Login', {});
});

// const trackRegisterCompleted = withRudderstack(async (r, userId: number) => {
//   const newId = String(userId);
//   const anonymousId = r.getAnonymousId();

//   r.alias(newId, anonymousId, undefined, () => {
//     r.track('User Registration Completed', {
//       anonymousId,
//       userId: newId,
//     });
//   });
// });

export const trackIdentify = withRudderstack(
  async (
    r,
    data: {
      profile?: ProfileData | null;
      accountInfo?: IUpdateAccountLevel | null;
      locale?: string;
    },
  ) => {
    const { locale, profile, accountInfo } = data;

    if (!profile || profile instanceof Error) {
      r.identify();
      return;
    }
    let other = {};

    if (accountInfo && !(accountInfo instanceof Error)) {
      const isFalsy = (v: any) => typeof v !== 'number' && _.isEmpty(v);
      other = _.omitBy(
        {
          first_name: accountInfo.firstName,
          last_name: accountInfo.lastName,
          date_of_birth: accountInfo.dateOfBirth,
          address: _.omitBy(
            {
              city: accountInfo.city,
              country: accountInfo.country,
              state: accountInfo.state,
              street: accountInfo.address,
            },
            isFalsy,
          ),
        },
        isFalsy,
      );
    }

    r.identify(String(profile.id), {
      username: profile.username,
      email: profile.email,
      locale,
      ...other,
    });
  },
);
