import BigNumber from 'bignumber.js';
import { atom } from 'jotai';
import _ from 'lodash';
import {
  fetchActiveCoins,
  fetchCoinPrice,
  listBalanceUserOwned,
} from 'src/api/wallet';
import { toUsd } from 'src/hooks/use-stake-coin';
import { CRYPTO_DP, FIAT_DP } from 'src/utils/constants/common-constant';
import { BN } from 'src/utils/helpers/big-number';
import {
  CoinItem,
  ListBalanceUserOwnedItem,
  PriceCoin,
} from 'src/utils/types/wallet-type';
import { loggedAtom, profileAtom } from './auth';
import { fiatAtom } from './layout';

export const _balancesAtom = atom<ListBalanceUserOwnedItem[]>([]);

export const balancesAtom = atom(
  (get) => {
    return get(_balancesAtom);
  },

  async (get, set) => {
    const items = await listBalanceUserOwned();

    set(_balancesAtom, items);
    return items;
  },
);

const _pricesAtom = atom<PriceCoin[]>([]);

const USDT = {
  coinId: -1,
  decimal: 18,
  id: -1,
  price: '1',
  symbol: 'usdt',
};

export const pricesAtom = atom(
  (get) => get(_pricesAtom),
  async (get, set) => {
    const res = await fetchCoinPrice();
    const next = [...res, USDT];

    set(_pricesAtom, next);
    return next;
  },
);

const _coinsAtom = atom<CoinItem[]>([]);

export const coinsAtom = atom(
  (get) => get(_coinsAtom),
  async (get, set, showfiat?: boolean) => {
    const items = await fetchActiveCoins({ showfiat });

    set(_coinsAtom, items);
    return items;
  },
);

export const depositedAtom = atom((get) => {
  const fromProfile = get(profileAtom)?.deposited;

  if (fromProfile) return true;
  const balances = get(balancesAtom);

  return (
    !_.isEmpty(balances) &&
    balances?.some((o) => new BN(o.balance_available).gt(0))
  );
});

export const totalBalanceInUsdAtom = atom((get) => {
  const prices = get(pricesAtom);
  const totalUsd = _.chain(get(balancesAtom))
    .map((b) => {
      const p = _.find(prices, { symbol: b.symbol });

      return new BN(b.balance).times(p?.price ?? '1');
    })
    .reduce((prev, curr) => {
      return prev.plus(curr);
    }, new BN(0))
    .value();

  return totalUsd.dp(FIAT_DP);
});

export const userBrokeAtom = atom((get) => {
  if (!get(loggedAtom)) return null;

  const fiat = get(fiatAtom);

  if (fiat) {
    return get(totalBalanceInUsdAtom).lte(0);
  }

  const balances = get(balancesAtom);
  return balances.every((s) =>
    new BN(s.balance_available).dp(CRYPTO_DP).lte(0),
  );
});

export interface PrettierBalances {
  balance_available: BigNumber;
  balance_available_in_usd: BigNumber;
  coin_id: number;
  symbol: string;
  name: string;
  decimal?: number | undefined;
  balance: string;
  balance_in_order: string;
}
export const prettierBalancesAtom = atom((get) => {
  const fiat = get(fiatAtom);
  const raw = get(balancesAtom);
  const fastPrices = _.keyBy(get(pricesAtom), (x) => x.symbol);
  const formattedBalance = raw.map((x) => ({
    ...x,
    balance_available: new BN(x.balance_available).dp(CRYPTO_DP),
    balance_available_in_usd: new BN(
      toUsd(x.balance_available, fastPrices[x.symbol]?.price ?? '1', FIAT_DP),
    ),
  }));
  if (fiat) {
    return formattedBalance.filter((x) => x.balance_available_in_usd.gt(0));
  }

  return formattedBalance.filter((x) => x.balance_available.gt(0));
});
