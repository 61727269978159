import { MarketGroupV2 } from './admin-v2/sportsbook';
import { BaseOutcome } from './outcome';
import { BetSource } from './sportsbook';

// DOCS: https://docs.betradar.com/display/BD/UOF+-+Sport+event+status
export module SportEvent {
  export interface PeriodScore {
    _match_status_code?: string;
    _number?: string;
    _home_score?: string;
    _away_score?: string;
  }

  export interface Clock {
    _match_time?: string;
    _remaining_time?: string;
    _remaining_time_in_period?: string;
  }

  export interface Root extends Clock {
    _match_status?: string;
    period_scores?: {
      period_score: PeriodScore[];
    };
    clock?: Clock;
    _home_score?: string;
    _home_gamescore?: string;
    _away_score?: string;
    _away_gamescore?: string;
    _status?: FixtureStatus;
  }
}

export type Nullable<T> = T | null;

interface MainPopularOutcome<OutcomeType extends BaseOutcome = BaseOutcome> {
  fixturePopularMarket: string;
  outrightPopularMarket?: string;
  marketOutcomes: Nullable<OutcomeType>[];
}

interface FixtureLanguage<OutcomeType extends BaseOutcome = BaseOutcome>
  extends EventCompetitors {
  marketNames: {
    [key: string]: string;
  };
  marketOutcomes: OutcomeType[];

  groupNames: { [key: string]: string };
}

export interface EventCompetitors {
  competitor1: string;
  competitor2: string;
  eventName?: string;
}
export interface TvChannel {
  _language: string;
  _name: string;
  _stream_url: string;
}

export interface Tournament {
  _id: string;
  tournamentId: string;
  name: string;
  languages?: {
    [key: string]: {
      name: string;
      customName: string;
    };
  };
}

interface ICategoryOfEvent {
  _id: string;
  categoryId: string;
  name: string;
  languages?: { customName: string };
}

interface ISport {
  _id: string;
  name: string;
  customName?: string;
  sportId: string;
  logo?: string;
  languages?: {
    [key: string]: {
      name: string;
      customName?: string;
    };
  };
}

// fixture and outright
export interface BaseEvent<OutcomeType extends BaseOutcome = BaseOutcome>
  extends EventCompetitors {
  _eventSlug?: string;
  tournamentId: string;
  sportId?: string; // TODO: old type => delete after update
  sport?: ISport;
  eventId: string;
  _id: string;
  liveodds: number;
  numberOfMarket?: string;
  sportEventStatus?: SportEvent.Root;
  startTime: string;
  status: FixtureStatus;
  mainPopularOutcomes: MainPopularOutcome<OutcomeType>;
  tvChannels?: { tv_channel: TvChannel | TvChannel[] };

  tournament?: Tournament; // for add fav season/league

  // #region stuffs may appear in detail
  source?: BetSource;
  color?: string;
  event?: string;

  // #endregion

  //#region stuffs may appear in outright
  isTopPick?: boolean | number;
  category?: ICategoryOfEvent;
  name?: string;
  categoryId?: string;
  priorityInCategory?: number;

  languages?: { [key: string]: FixtureLanguage<OutcomeType> };
  // #endregion
}

export interface DetailEventInterface extends BaseEvent {
  fixtureMarketGroups: MarketGroupV2[];

  status: number;

  tournament: Tournament;
  templates: TemplateInterface[];
}

export interface TemplateInterface {
  active: number;
  markets: MarketInterface[];
  name: string;
  groupkey: string;
  marketName: string;
  market_id: string | number;
  marketSpecifiers: string;
  marketId: string;
  marketPriority: number;
  groupRank: number;
}

export interface MarketInterface {
  active: number;
  specifiers: string;
  outcomeGroups: OutcomeGroupInterface[];
}

export interface OutcomeGroupInterface {
  groupName: string;
  outcomes: BaseOutcome[];
}

export interface ICategory {
  categoryName?: string;
  categoryId?: string;
  tournaments?: ITournament[];

  // outright
  category?: string; // outright
  events?: BaseEvent[]; // outright

  languages?: {
    [key: string]: {
      customName: string;
    };
  };
}

export interface ITournament {
  _id: string;
  tournamentId: string;
  categoryId?: string;
  sportId?: string;
  name?: string;
  priorityInCategory?: number;
  isTopPick?: boolean | number;
  __events?: BaseEvent[];
  languages?: {
    [key: string]: {
      name: string;
    };
  };
}

export enum FixtureStatus {
  FUTURE = -2,
  LEAGUE = -1,
  NOT_STARTED = 0,
  LIVE = 1,
  ENDED = 3,
  CLOSED = 4,
  CANCELLED = 5,
  DELAYED = 6,
  INTERRUPTED = 7,
  POSTPONED = 8,
  ABANDONED = 9,
}
