import { t } from '@lingui/macro';

export const getResponseMsg = (code: string | number) => {
  switch (String(code)) {
    default:
    case '99':
      return t({
        message: `An error occurred, please try again (${code}).`,
        id: 'error.api.unknown',
      });
    case '01':
      return t({ message: `Bad request`, id: 'error.api.01' });
    case '02':
      return t({
        message: `Page index isn't integer`,
        id: 'error.api.02',
      });
    case '03':
      return t({
        message: `Page index must be greater than or equar to 1`,
        id: 'error.api.03',
      });
    case '04':
      return t({
        message: `Page size isn't integer`,
        id: 'error.api.04',
      });
    case '05':
      return t({
        message: `Page size must be greater than or equar to 1`,
        id: 'error.api.05',
      });
    case '06':
      return t({ message: `User existed`, id: 'error.api.06' });
    case '09':
      return t({ message: `Send email error`, id: 'error.api.09' });
    case '10':
      return t({ message: `Send SMS error`, id: 'error.api.10' });
    case '11':
      return t({
        message: `Email already exist. Please try another one.`,
        id: 'error.api.11',
      });
    case '12':
      return t({
        message: `Player name already exist. Please try another one.`,
        id: 'error.api.12',
      });
    case '13':
      return t({
        message: `Incorrect email or password.`,
        id: 'error.api.13',
      });
    case '14':
      return t({
        message: `User not existed`,
        id: 'error.api.14',
      });
    case '15':
      return t({ message: `password is weak`, id: 'error.api.15' });
    case '16':
      return t({
        message: `Player name or email exist in auth0`,
        id: 'error.api.16',
      });
    case '17':
      return t({ message: `user is blocked`, id: 'error.api.17' });
    case '18':
      return t({ message: `OTP is not correct.`, id: 'error.otp.incorrect' });
    case '19':
      return t({ message: `Email not matching`, id: 'error.api.19' });
    case '20':
      return t({
        message: `Player name accept number and character`,
        id: 'error.api.20',
      });
    case '21':
      return t({
        message: `Password must contain at least 8 characters, 1 number, 1 upper case and 1 special character - Length: 8 to 30`,
        id: 'error.api.21',
      });
    case '22':
      return t({ message: `age invalid`, id: 'error.api.22' });
    case '23':
      return t({
        message: `Email address does not exist.`,
        id: 'error.api.23',
      });
    case '24':
      return t({ message: `Password is not match`, id: 'error.api.24' });
    case '25':
      return t({ message: `Password is incorrect`, id: 'error.api.25' });
    case '26':
      return t({ message: `Email invalid`, id: 'error.api.26' });
    case '27':
      return t({ message: `User  don't have  balance`, id: 'error.api.27' });
    case '28':
      return t({ message: `Coin not found`, id: 'error.api.28' });
    case '29':
      return t({ message: `Can not get rate`, id: 'error.api.29' });
    case '30':
      return t({ message: `Request wallet error`, id: 'error.api.30' });
    case '31':
      return t({ message: `Not found network active`, id: 'error.api.31' });
    case '32':
      return t({ message: `Referral code does not exist`, id: 'error.api.32' });
    case '33':
      return t({ message: `Address is not found`, id: 'error.api.33' });
    case '34':
      return t({ message: `INSUFFICIENT_BALANCE`, id: 'error.api.34' });
    case '35':
      return t({
        message: `ADDRESS_SENDER_WITHDRAW_NOT_FOUND`,
        id: 'error.api.35',
      });
    case '36':
      return t({ message: `Can not withdraw`, id: 'error.api.36' });
    case '37':
      return t({ message: `ADDRESS_DEPOSIT_NOT_FOUND`, id: 'error.api.37' });
    case '38':
      return t({
        message: `Price has changed by over 0.5% since the last refresh.\nClick the top right refresh button to get the new quote.`,
        id: 'error.api.38',
      });
    case '39':
      return t({
        message: `An unexpected error has occurred\nClick the top right refresh button to get the new quote., `,
        id: 'error.api.39',
      });
    case '40':
      return t({ message: `NOT_ENOUGH_BALANCE`, id: 'error.api.40' });
    case '41':
      return t({ message: `NOT_REACH_MIN_AMOUNT`, id: 'error.api.41' });
    case '42':
      return t({ message: `EXCEED_MAX_AMOUNT`, id: 'error.api.42' });
    case '43':
      return t({ message: `INVALID_SWAP_INPUT`, id: 'error.api.43' });
    case '44':
      return t({ message: `NOT_FOUND_FIXTURE`, id: 'error.api.44' });
    case '45':
      return t({ message: `REQUEST_BETRADAR_ERROR`, id: 'error.api.45' });
    case '46':
      return t({ message: `NOT_FOUND_SWAP_CONFIG`, id: 'error.api.46' });
    case '47':
      return t({ message: `AMOUNT_IN_TOO_LOW`, id: 'error.api.47' });
    case '48':
      return t({ message: `BET_FAILED`, id: 'error.api.48' });
    case '49':
      return t({ message: `GAME_INACTIVE`, id: 'error.api.49' });
    case '50':
      return t({ message: `FIXTURE_INACTIVE`, id: 'error.api.50' });
    case '51':
      return t({ message: `TIME_OVER`, id: 'error.api.51' });
    case '52':
      return t({ message: `INVALID_STAKE`, id: 'error.api.52' });
    case '53':
      return t({ message: `INVALID_ODDS`, id: 'error.api.53' });
    case '54':
      return t({ message: `CAN_NOT_PLACE_BET_TWICE`, id: 'error.api.54' });
    case '55':
      return t({ message: `NOT_FOUND_BET_GAME`, id: 'error.api.55' });
    case '56':
      return t({ message: `TIME_FIXTURE_NOT_IN_TIME_DIV`, id: 'error.api.56' });
    case '58':
      return t({ message: `NOT_FOUND_MULTI_GAME`, id: 'error.api.58' });
    case '59':
      return t({ message: `FANTASY_USER_EXISTS`, id: 'error.api.59' });
    case '60':
      return t({ message: `BET_GAME_NOT_IN_FIXTURE`, id: 'error.api.60' });
    case '61':
      return t({ message: `SPORT_BOOK_INACTIVE`, id: 'error.api.61' });
    case '66':
      return t({
        message: `CAN_NOT_MULTI_BET_SAME_FIXTURE`,
        id: 'error.api.66',
      });
    case '68':
      return t({ message: `Incorrect password.`, id: 'error.api.68' });
    case '67':
      return t({
        message: `Verification code is not correct.`,
        id: 'error.api.67',
      });
    case '69':
      return t({
        message: `This account has been deactivated.`,
        id: 'error.api.69',
      });
    case '74':
      return t({ message: `Round is pending`, id: 'error.api.74' });
    case '80':
      return t({ message: `NOT_FOUND_CONFIGS`, id: 'error.api.80' });
    case '83':
      return t({
        message: `HOLD_AMOUNT_LESS_THAN_MIN_STAKE_CONFIG`,
        id: 'error.api.83',
      });
    case '84':
      return t({
        message: `HOLD_AMOUNT_GREATER_THAN_MAX_STAKE_CONFIG`,
        id: 'error.api.84',
      });
    case '92':
      return t({ message: `NOT_FOUND_KYC`, id: 'error.api.92' });
    case '96':
      return t({
        message: `There was a problem placing your bets. Please try again or contact support.`,
        id: 'error.api.96',
      });
    case '103':
      return t({
        id: 'error.account_exclude',
        message:
          'You cannot place any bets because your account is under excluded period. Please contact our support team for further assistance.',
      });

    case '153':
      return t({
        id: 'casino.detail.error.not_support_country',
        message:
          'Unfortunately this game provider does not support players from your current country.',
      });
    case '117':
      return t({
        id: 'error.affiliate.existed_code',
        message: `Code already exist.`,
      });
    case '338':
      return t({
        id: 'error.api.338',
        message: 'Player name has changed',
      });
    case '337':
      return t({
        id: 'error.api.337',
        message: 'Your Email has been verified',
      });
    case '153':
      return t({
        id: 'error.api.153',
        message:
          'This game is available for play, but is not accessible in your current location',
      });
    case '700':
      return t({
        id: 'error.deposit.cpf_invalid',
        message: 'Your CPF is invalid. Please check and try again.',
      });
  }
};
