'use client';

import { Trans } from '@lingui/macro';
import { IconButton, List, MenuItem, Popover } from '@mui/material';
import _ from 'lodash';
import { useCallback, useRef, useState } from 'react';
import DoubleArrowIcon from 'src/assets/icons/double-arrow.svg';
import { CasinoFilter } from '../casino-filter';
import { useStyles } from './styles';

export enum SortGameEnum {
  POPULAR = 1,
  AZ = 2,
  ZA = 3,
}

export interface SortOption {
  label: React.ReactNode;
  key: string;
  value: SortGameEnum;
}

interface ProducerSorterProps {
  options?: SortOption[];
  value?: SortOption;
  onChange?: (next: SortOption) => any;
  icon?: boolean;
}

export const ProducerSorter: React.FCC<ProducerSorterProps> = (props) => {
  const { options, value, onChange, icon = false } = props;

  const [open, setOpen] = useState(false);
  const anchorRef = useRef<any>();
  const { classes } = useStyles();

  const handleClose = useCallback(() => setOpen(false), []);

  return (
    <>
      {icon ? (
        <IconButton
          size="large"
          sx={{ my: -1.5, mx: -1, px: 1 }}
          ref={anchorRef}
          onClick={() => setOpen(true)}>
          <DoubleArrowIcon width={24} />
        </IconButton>
      ) : (
        <CasinoFilter
          ref={anchorRef}
          label={<Trans id="casino.sort_by">Sort By:</Trans>}
          value={value?.label}
          open={open}
          onToggle={() => setOpen(true)}
        />
      )}

      {open && (
        <Popover
          open={open}
          anchorEl={anchorRef.current}
          componentsProps={{
            backdrop: {
              invisible: true,
              onTouchMove: handleClose,
              className: classes.backdrop,
            },
          }}
          slotProps={{ paper: { className: classes.popoverPaper } }}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: icon ? 'left' : 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: icon ? 'left' : 'right',
          }}
          onClose={handleClose}>
          <List>
            {_.map(options, (obj) => {
              const selected = value?.key === obj.key;

              return (
                <MenuItem
                  selected={selected}
                  onClick={() => {
                    onChange?.(obj);
                    handleClose();
                  }}
                  className={classes.item}
                  key={obj.value}>
                  {obj.label}
                </MenuItem>
              );
            })}
          </List>
        </Popover>
      )}
    </>
  );
};
