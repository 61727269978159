import _ from 'lodash';
import { weights } from 'src/utils/constants/icon-coins';
import { EMAIL_DOMAIN } from 'src/utils/constants/keys';
import { getResponseMsg } from 'src/utils/constants/response-code';
import { request } from 'src/utils/request';
import { BaseResponse, PaginationResponse } from 'src/utils/types/response';
import {
  AddressCheckingExistedRequest,
  CoinItem,
  ListBalanceUserOwnedItem,
  NetworkItem,
  PriceCoin,
  TxHistory,
  TxHistoryRequest,
} from 'src/utils/types/wallet-type';
import {
  GetAmountConfigRequest,
  GetFeeWithdrawRequest,
  GetFeeWithdrawResponse,
  SubmitWithdrawRequest,
  SubmitWithdrawResponse,
  WithdrawConfig,
} from 'src/utils/types/withdraw-type';

export const fetchActiveNetworks = async (coin_symbol?: string) => {
  const res = await request.get<BaseResponse<{ items: NetworkItem[] }>>(
    '/network/list-active',
    { params: { coin_symbol } },
  );

  return res.data;
};

export const listBalanceUserOwned = async () => {
  const res =
    await request.get<BaseResponse<{ items: ListBalanceUserOwnedItem[] }>>(
      `/wallet/list-balance`,
    );

  const sorted = _.chain(res.data.data.items)
    .filter((x) => Boolean(x.symbol))
    .sortBy((o) => _.findIndex(weights, (symbol) => symbol === o.symbol) + 1)
    .value();

  return sorted;
};

export const getTxHistory = async (body: TxHistoryRequest) => {
  const clean = _.omitBy(
    body,
    (v, k) => v === 'all' || v === -2 || (typeof v === 'string' && !v),
  );

  const { data } = await request.post<PaginationResponse<TxHistory>>(
    '/transaction/list',
    clean,
  );

  for (const i of data.data.items) {
    if (typeof i.transaction_date === 'number') {
      i.transaction_date = i.transaction_date / 1000;
    }
  }

  return data.data;
};

export const fetchActiveCoins = async ({ showfiat = true }) => {
  const {
    data: { data },
  } = await request.get<BaseResponse<{ items: CoinItem[] }>>(
    `/coin/list-active`,
    { params: { showfiat } },
  );

  return data.items;
};
export const fetchWithdrawableCoins = async () => {
  const { data } = await request.get<BaseResponse<string[]>>(
    '/transaction/get-withdrawable-coin',
  );
  return data.data;
};

export const getAddressActiveOfWallet = async (network_id?: number) => {
  const { data } = await request.get<BaseResponse<{ address: string }>>(
    '/wallet/get-address',
    { params: { network_id } },
  );
  return data;
};

export const checkExistedAddressOfWallet = async (
  body: AddressCheckingExistedRequest,
) => {
  try {
    const { data } = await request.post<
      BaseResponse<{ address_existed: boolean }>
    >('/wallet/check-existed-address', body);
    if (!data.data.address_existed) {
      throw { code: '33' };
    }

    return true;
  } catch (err: any) {
    err.code = '33';
    err.message = getResponseMsg(err.code);
    throw err;
  }
};

export const fetchConfigWithdraw = async (body: GetAmountConfigRequest) => {
  const { data } = await request.post<BaseResponse<WithdrawConfig>>(
    '/transaction/get-amount-config',
    body,
  );
  return data.data;
};

export const fetchFeeWithdraw = async (body: GetFeeWithdrawRequest) => {
  const { data } = await request.post<BaseResponse<GetFeeWithdrawResponse>>(
    '/transaction/get-fee',
    body,
  );
  return data.data;
};

export const callApiSubmitWithDraw = async (body: SubmitWithdrawRequest) => {
  const res = await request.post<BaseResponse<SubmitWithdrawResponse>>(
    '/transaction/withdraw',
    {
      ...body,
      domain: EMAIL_DOMAIN,
    },
  );
  return res.data;
};

export const fetchCoinPrice = async () => {
  const { data } =
    await request.get<BaseResponse<PriceCoin[]>>('coin/get-all-price');
  return data.data;
};

export const getWithawableValue = async (coin: string) => {
  const { data } = await request.get<BaseResponse<{ udAmount: number }>>(
    `/muser/get-withdrawable-amount/${coin}`,
  );

  return data.data.udAmount;
};
